/* Tyre.css */

.tyre-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.tyre-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #079307; /* Green color for the title */
  text-align: center;
}

.tyre-subtitle {
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #079307; /* Green color for subtitles */
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}

.tyre-description {
  font-size: 1.1em;
  margin-bottom: 20px;
  text-align: justify;
}

.tyre-benefits-list {
  margin: 20px 0;
  padding-left: 20px;
  list-style-type: disc;
}

.tyre-benefits-list li {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.tyre-step-title {
  font-size: 1.5em;
  color: #079307; /* Green color for step titles */
  margin-top: 20px;
  margin-bottom: 10px;
}
.tyre-waste-management{
background-color: #ffffff;
padding: 15px;
margin-bottom: 30px;
border-left: 5px solid #079307;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.tyre-step-content {
  font-size: 1.1em;
  margin-bottom: 15px;
  text-align: justify;
}

.tyre-container h2,
.tyre-container h3 {
  font-weight: bold;
}

.tyre-container ul {
  padding-left: 20px;
}

.tyre-container ul li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .tyre-title {
    font-size: 2em;
  }

  .tyre-subtitle {
    font-size: 1.6em;
  }

  .tyre-step-title {
    font-size: 1.4em;
  }

  .tyre-description, 
  .tyre-step-content, 
  .tyre-benefits-list li {
    font-size: 1em;
  }
}
