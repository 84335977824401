/* Organic.css */
img{
  max-width:950px;
  height:650px;
}
.organic-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.organic-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #079307; /* Green color for the title */
  text-align: center;
}

.organic-subtitle {
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #079307; /* Green color for subtitles */
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}
.organic-waste-management{
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 30px;
  border-left: 5px solid #079307;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.organic-description,
.organic-process-description {
  font-size: 1.1em;
  margin-bottom: 20px;
  text-align: justify;
}

.organic-benefits-list,
.organic-recycling-methods-list,
.organic-products-list {
  margin: 20px 0;
  padding-left: 20px;
  list-style-type: disc;
}

.organic-benefits-list li,
.organic-recycling-methods-list li,
.organic-products-list li {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.organic-process-step {
  font-size: 1.5em;
  color: #079307; /* Green color for process steps */
  margin-top: 20px;
  margin-bottom: 10px;
}

.organic-conclusion {
  font-size: 1.1em;
  margin-bottom: 20px;
  text-align: justify;
}

@media (max-width: 768px) {
  .organic-title {
    font-size: 2em;
  }

  .organic-subtitle,
  .organic-process-step {
    font-size: 1.6em;
  }

  .organic-description, 
  .organic-process-description, 
  .organic-benefits-list li,
  .organic-recycling-methods-list li,
  .organic-products-list li {
    font-size: 1em;
  }
}
