/* Product & Service Section */
.products-section {
  padding: 30px 0px;
  margin-top: 95px;
  background-color: #f9fafb;
  color: #333;
  text-align: center;
  background: white;
}

.products-section h2 {
  font-size: 3rem;
  font-weight: 700;
  color: #079307; /* Highlight the title */
  margin-bottom: 40px;
}

.product-cards-container {
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  justify-content: flex-start; /* Align cards vertically */
  align-items: center; /* Center align the cards horizontally */
  gap: 40px; /* Space between cards */
  /* margin: 0;  */
  width: 100%; /* Full width */
}

/* General product card styles */
.product-card {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 1000px; /* Consistent max width for each card */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  background-image: url("./Images/recycled-paper1.png");
}
.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}
.product-card h3 {
  font-size: 1.6rem;
  /* margin-top: 60px; */
  margin-bottom: 15px;
  color: #079307;
  text-align: center;
  font-family: "Montserrat", Sans-serif;
}
.product-card li {
  color: #666;
  font-family: "Montserrat", Sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}
.product-image {
  width: 100%;
  margin-bottom: 20px;
}
.product-card:nth-child(odd) {
  align-self: flex-start; /* Align odd cards to the left */
}
.product-card:nth-child(even) {
  align-self: flex-end; /* Align even cards to the right */
}

.epr-services-image{
  border-radius: 15px;
  max-width: 650px;
  height: 400px;
}
.e-waste-management-image{
  border-radius: 15px;
  max-width: 650px;
  height: 400px;
}
.battery-waste-management-image{
  border-radius: 15px;
  margin-left: 50px;
  height: 400px;
}
.plastic-waste-management-image{
  border-radius: 15px;
  max-width: 650px;
  height: 400px;
}
.tyre-waste-management-image{
  width: 630px;
  height: 400px;
  border-radius: 15px;
}
.used-oil-management-image{
  border-radius: 15px;
  max-width:650px;
  height: 400px;
}
.sustainability-auditing-image{
  border-radius: 15px;
  max-width:650px;
  height: 400px;
}
.organic-waste-image{
  border-radius: 15px;
  max-width:650px;
  height: 400px;
}
.medical-waste-services-image{
  border-radius: 15px;
  max-width:650px;
  height: 400px;
}
.landfill-waste-image{
  border-radius: 15px;
  max-width:650px;
  height: 400px;
}
.esg-reporting-services-image{
  border-radius: 15px;
  width:650px;
  height: 400px;
}
.carbon-services-image{
  border-radius: 15px;
  max-width:650px;
  height: 400px;
}


@media (max-width: 768px) {
  .products-section h2 {
    font-size: 2rem; /* Smaller heading size */
  }

  .product-cards-container {
    flex-direction: column; /* Stack cards vertically on smaller screens */
    align-items: center;
  }

  .product-card {
    flex-direction: column; /* Stack content and image vertically */
    max-width: 90%; /* Ensure it doesn't overflow */
  }

  .product-image {
    width: 100%;
    margin-bottom: 10px;
  }

  .product-content {
    width: 100%;
    text-align: center;
  }
.epr-services-image,
.e-waste-management-image,
.plastic-waste-management-image,
.used-oil-management-image,
.sustainability-auditing-image,
.organic-waste-image,
.medical-waste-services-image,
.landfill-waste-image,
.esg-reporting-services-image,
.carbon-services-image,
.tyre-waste-management-image,
.battery-waste-management-image {
  border-radius: 15px;
  width: 100%; /* Full width for all images */
  max-width: 650px; /* Consistent max width */
  height: auto; /* Maintain aspect ratio */
}
.battery-waste-management-image {
  margin-left:0px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}
.loader {
  width: 70px;
  height: 70px;
}
}

/* Header Text Styling */
.arrowlogo {
  width: 60px;
  height: auto;
}

.circularlogo {
  width: 75px;
  height: auto;
}

.header-container {
  position: relative;
  /* height: 365px; */
  height: calc(100vh - 240px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}
.video-section {
  position: relative;
  width: 100vw;
  overflow: hidden;      /* Hide any overflow outside of the container */
}

.main-header {
  color: white; /* Fill the text with white */
  font-size: 3.5rem;
  margin: 0;
  padding: 0;
  font-family: __Inter_0ec1f4, __Inter_Fallback_0ec1f4;
}

.special-header {
  background-image: url('./Images/grass.jpeg'); /* Path to the green leaves image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background 0.5s ease; /* Smooth transition for background change */
}

/* General Styles */
body {
  margin: 0;
  font-family:  sans-serif;
}

html, body {
  height: 100%;
  margin: 0;
  font-family: sans-serif;
}

/* App Bar Styling */
.appbar {
  background-color: transparent !important; /* Ensure the background is fully transparent */
  box-shadow: none !important;
  position: fixed;
  width: 100%;
  z-index: 10;
  padding-top: 0px;
  transition: background 0.5s ease;
}

/* Ensure no other background color is applied */
.MuiAppBar-root {
  background-color: transparent !important;
}

@media (max-width: 768px) {
  /* Hide the desktop navigation on mobile */
  .nav-buttons-container {
    display: flex;
  }
  .social-icons {
    display: none;
  }

  /* Sidebar menu styling */
  .MuiDrawer-paper {
    background-color: transparent !important;
  }

  /* Mobile menu button styling */
  .nav-button {
    padding: 15px;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .appbar,.logo-image {
    margin: 0px;
    padding:0px;
  }
  .social-icons {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: #fff;
  }

  .MuiSvgIcon-root {
    color: #fff; /* Ensure social media icons are white in drawer */
  }
  .main-header {
    color: white; /* Fill the text with white */
    font-size: 3.5rem;
    margin: 0;
    padding: 0;
    font-family: 'Rozha One', serif;
  }
}
.logo-image {
  margin:0px;
  height: auto;
  width: 170px;
  background-size: cover;
  transition: 0.4s ease, transform 0.4s ease;
}

.logo-image:hover {
  transform: scale(1.3);
}

.nav-buttons-container {
  display: flex;
  justify-content: center ; /* Center horizontally */
  align-items: center; /* Align items vertically in the center */
  width: 100%; /* Ensure it spans the entire width */
  font-weight: bold;
}

/* Navigation Buttons */
.nav-button {
  color: white !important; /* Ensure text is visible */
  font-size: 0.875rem;
  text-transform: none;
  text-decoration: none; /* Removes underline from links */
  cursor: pointer;
  padding: 8px 16px;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: #30e630 !important;
}

/* Social Icons */
.social-icons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
}

.social-icons .MuiSvgIcon-root {
  margin-right: 20px;
  font-size: 1.5rem;
  color: white !important; /* Ensure icons are visible */
  transition: color 0.3s ease;
}

.social-icons .MuiSvgIcon-root:hover {
  color: #30e630 !important;
}


.whatsapp-float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: #25D366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp-icon {
  width: 35px;
  height: 35px;
}

.whatsapp-float:hover {
  background-color: #30e630; 
}

/* Styling for the mail icon */
.mail-icon-container {
  position: fixed;
  bottom: 100px; /* Adjust to appear above WhatsApp */
  right: 40px;
  background-color: #25D366;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1001; /* Ensure it appears above other content */
}

.mail-icon {
  color: white;
  font-size: 24px;
}

.mail-icon-container:hover {
  background-color: #30e630; /* Darker blue on hover */
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Dimmed background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  width: 450px;
  max-width: 90%;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.close-modal {
  position: absolute;
  top: 0px;
  right: 3px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.enquiry-form label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: normal;
}

.enquiry-form input,
.enquiry-form textarea {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #333;
}

.enquiry-form input {
  height: 35px;
}

.enquiry-form textarea {
  height: 100px;
  resize: none; /* No resize handle for the textarea */
}

.enquiry-form button {
  background-color: #079307;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.enquiry-form button:hover {
  background-color: #24ba5b; /* Slightly lighter on hover */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.loader {
  width: 100px;
  height: 100px;
}
@media (max-width: 768px) {
  .footer-container {
    max-width: 100px;
    margin: 0 auto;

  }
  .footer {
    max-width: 100%;
    margin: 0 auto;
  }
  
.footer-row {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.footer-column {
  display:flex;
  flex-direction: column;
  padding: 0px;
  align-items:center;
}

.footer-logo {
  align-items:center;
  display:flex;
  flex-direction: column;
  margin: 0px;
  padding:0px;
  height:200px;
  width:250px;
}

.footer-address {
  flex-direction: column;
  text-align:center;
  margin: 0px;
  font-size:1.25rem;
}

.footer-text {
  flex-direction: column;
  color: #ffffff;
  margin: 0;
  padding: 0px;
}

.footer-links {
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.footer-links li {
  flex-direction: column;
  margin-bottom: 8px;
}

.footer-links a {
  flex-direction: column;
  text-decoration: none;
  color: #ffffff; /* Link color */
}

.footer-links a:hover {
  flex-direction: column;
  text-decoration: underline;
}

.social-icons {
  flex-direction: row;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icons a {
  flex-direction: column;
  color: #333;
  font-size: 24px;
}

.footer-contact {
  flex-direction: column;
  margin-top: 15px;
  text-align: center;
}

.copyright {
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  color: #000000;
  font-size: 2rem;
}
}
.footer {
  background-image: url("./Images/footerimage.png"); /* Light background */
  padding: 0px 0;
  font-family: __Inter_0ec1f4, __Inter_Fallback_0ec1f4;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-row {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.footer-column {
  padding: 0px;
  text-align: left;
}
.footer-logo {
  display: flex;
  align-items: center;
  margin:0px;
  padding-left:40px;
  height:200px;
  width:250px;
}

.footer-address {
  text-align: center;
  margin: 0px;
  font-size:1.25rem;
}

.footer-text {
  color: #ffffff;
  margin: 0;
  padding: 0px;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.footer-links li {
  margin-bottom: 8px;
}

.footer-links a {
  text-decoration: none;
  color: #ffffff; /* Link color */
}

.footer-links a:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icons a {
  color: #333;
  font-size: 24px;
}

.footer-contact {
  margin-top: 15px;
  text-align: center;
}

.copyright {
  text-align: center;
  margin-top: 20px;
  color: #000000;
  font-size: 15px;
}

.background-video-mob,
.background-video-web {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;  
  height: 100vh; 
  object-fit: cover;  
  z-index: -1;    
  overflow: hidden;
  object-fit: cover;   
}

/* Default for large screens */
.header-container h1 {
  text-align: center;
  margin:0px;
}

/* For medium-sized screens (tablets) */
@media (max-width: 1024px) {
  .header-container h1 {
    margin-bottom: 15vh; /* Adjusted to 15% of the viewport height */
  }
}

/* For smaller screens (mobile devices) */
@media (max-width: 768px) {
  .header-container h1 {
    margin-bottom: 10vh; /* Adjusted to 10% of the viewport height */
  }
}



/* Ensure no additional padding or margins */
.MuiToolbar-root {
  padding: 0 !important;
  margin: 0 !important;
}

.toolbar {
  background-color: transparent !important;
}

.reko-container {
  text-align: center;
  margin-top: 43px;
  width: 100%;
  /* padding: 0; */

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.reko-container img {
  width: 100%;             /* Image will scale to the width of the container */
  max-width: 1254px;       /* Sets a maximum width to prevent it from getting too large */
  height: auto;            /* Keeps the aspect ratio intact */
}

/* For smaller screens (mobile devices) */
@media (max-width: 768px) {
  .reko-container img {
    max-width: 100%;        /* Limits the width to 90% of the screen width on smaller screens */
  }
}
