/* Carbon.css */

.carbon-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.carbon-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #079307; /* Green color for the title */
  text-align: center;
}

.carbon-subtitle {
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #079307; /* Green color for subtitles */
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}

.carbon-description {
  font-size: 1.1em;
  margin-bottom: 20px;
  text-align: justify;
}

.carbon-section-title {
  font-size: 1.5em;
  color: #079307; /* Green color for section titles */
  margin-top: 20px;
  margin-bottom: 10px;
}
.carbon-management{
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 30px;
  border-left: 5px solid #079307;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.carbon-benefits-list {
  margin: 20px 0;
  padding-left: 20px;
  list-style-type: disc;
}

.carbon-benefits-list li {
  font-size: 1.1em;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .carbon-title {
    font-size: 2em;
  }

  .carbon-subtitle,
  .carbon-section-title {
    font-size: 1.6em;
  }

  .carbon-description, 
  .carbon-benefits-list li {
    font-size: 1em;
  }
}
