/* ESG.css */
img{
  max-width:1050px;
  height:650px;
}
.esg-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.esg-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #079307; /* Green color for the title */
  text-align: center;
}

.esg-subtitle {
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #079307; /* Green color for subtitles */
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}
.esg-reporting-process{
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 30px;
  border-left: 5px solid #079307;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.esg-description {
  font-size: 1.1em;
  margin-bottom: 20px;
  text-align: justify;
}

.esg-section-title {
  font-size: 1.5em;
  color: #079307; /* Green color for section titles */
  margin-top: 20px;
  margin-bottom: 10px;
}

.esg-benefits-list,
.esg-details-list {
  margin: 20px 0;
  padding-left: 20px;
  list-style-type: disc;
}

.esg-benefits-list li,
.esg-details-list li {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.esg-conclusion {
  font-size: 1.1em;
  margin-bottom: 20px;
  text-align: justify;
}

@media (max-width: 768px) {
  .esg-title {
    font-size: 2em;
  }

  .esg-subtitle,
  .esg-section-title {
    font-size: 1.6em;
  }

  .esg-description, 
  .esg-conclusion, 
  .esg-benefits-list li,
  .esg-details-list li {
    font-size: 1em;
  }
  img{
    border-radius: 15px;
    width: 100%; /* Full width for all images */
    width: 400px; /* Consistent max width */
    height: 300px; /* Maintain aspect ratio */
  }
}
